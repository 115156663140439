import { UserType } from "~/assets/scripts/valueObjects/UserType";
const cookieMaxAge = 60 * 30;
let tokenRenewalTimeout = null;

export const state = () => ({
	isFirstLogin: false,
	user: {
		profileId: null,
		userId: null,
		firstName: "",
		lastName: "",
		email: "",
		country: "",
		isAppLogin: false,
		phone: "",
		phoneCountryCode: null,
		expiresAt: null,
		timeZoneOffset: null,
		street: null,
		town: null,
		city: null,
		postCode: null,
		creditCardModel: null,
		userType: UserType.Guest,
	},
	dashboard: {
		licenceDetailModel: null,
		creditCardModel: null,
		profileModel: null,
		myAccountRentals: null,
	},
	agentDetails: null,
	tokenInfo: {
		isAuthenticated: false,
		token: "",
		tokenExpiresAt: null,
	},
});

export const mutations = {
	firstLogin(state, value) {
		state.isFirstLogin = value;
	},
	updateToken(state, tokenInfo) {
		state.tokenInfo = tokenInfo;
		this.$cookies.set("tokenInfo", tokenInfo, { path: "/", maxAge: cookieMaxAge });
	},
	updateUser(state, user) {
		state.user = user;
		this.$cookies.set("user", user, { path: "/", maxAge: cookieMaxAge });
	},
	loginUser(state, user) {
		state.user.profileId = user.profileId;
		state.user.firstName = user.firstName;
		state.user.lastName = user.lastName;
		state.user.email = user.email;
		state.user.country = user.country;
		state.user.phone = user.phone;
		state.user.phoneCountryCode = user.phoneCountryCode;
		state.user.creditCardModel = user.creditCardModel
		state.user.expiresAt = user.expiresAt;
		state.user.timeZoneOffset = user.timeZoneOffset;
		state.user.street = user.street;
		state.user.town = user.town;
		state.user.city = user.city;
		state.user.postCode = user.postCode;
		state.user.userId = user.userId;
		state.user.isAppLogin = !!user?.isAppLogin;
		state.user.userType = user?.userType || UserType.Guest;

		this.$cookies.set("user", JSON.stringify(state.user), { path: "/", maxAge: cookieMaxAge });

		state.tokenInfo = {
			isAuthenticated: true,
			token: user.token,
			tokenExpiresAt: state.user.expiresAt,
		};

		this.$cookies.set("tokenInfo", state.tokenInfo, { path: "/", maxAge: cookieMaxAge });
	},
	logOutUser(state) {
		state.user.profileId = null;
		state.user.userId = null;
		state.user.firstName = "";
		state.user.lastName = "";
		state.user.email = "";
		state.user.country = "";
		state.user.phone = "";
		state.user.phoneCountryCode = null;
		state.user.expiresAt = null;
		state.user.timeZoneOffset = null;
		state.user.street = null;
		state.user.town = null;
		state.user.city = null;
		state.user.postCode = null;
		state.user.creditCardModel = null;
		state.user.userType = UserType.Guest;
		state.tokenInfo = {
			isAuthenticated: false,
			token: "",
			tokenExpiresAt: null,
		};
		state.dashboard = {
			licenceDetailModel: null,
			creditCardModel: null,
			profileModel: null,
			myAccountRentals: null,
		};
		state.agentDetails = null

		this.$cookies.remove("user");
		this.$cookies.remove("tokenInfo");
	},
	updateDashboard(state, dashboardData) {
		if (!!dashboardData.creditCardModel && !!dashboardData.creditCardModel.expiryDate && dashboardData.creditCardModel.expiryDate !== "0001-01-01T00:00:00") {
			dashboardData.creditCardModel.expiryDate = this.$date.timeModeToDate(dashboardData.creditCardModel.expiryDate, { month: "2-digit", year: "numeric" });
		} else if (dashboardData.creditCardModel) {
			dashboardData.creditCardModel.expiryDate = null;
		}
		if (dashboardData.licenceDetailModel) {
			if (dashboardData.licenceDetailModel.dateOfBirth && dashboardData.licenceDetailModel.dateOfBirth !== "0001-01-01T00:00:00") {
				dashboardData.licenceDetailModel.dateOfBirth = this.$date.timeModeToDate(dashboardData.licenceDetailModel.dateOfBirth, { day: "2-digit", month: "2-digit", year: "numeric" });
			} else if (dashboardData.licenceDetailModel) {
				dashboardData.licenceDetailModel.dateOfBirth = null;
			}
			if (dashboardData.licenceDetailModel.expiryDate && dashboardData.licenceDetailModel.expiryDate !== "0001-01-01T00:00:00") {
				dashboardData.licenceDetailModel.expiryDate = this.$date.timeModeToDate(dashboardData.licenceDetailModel.expiryDate, { day: "2-digit", month: "2-digit", year: "numeric" });
			} else if (dashboardData.licenceDetailModel) {
				dashboardData.licenceDetailModel.expiryDate = null;
			}
		}
		if (!!dashboardData.licenceDetailModel && !!dashboardData.profileModel.createDateTime && dashboardData.profileModel.createDateTime !== "0001-01-01T00:00:00") {
			dashboardData.profileModel.createDateTime = this.$date.timeModeToDate(dashboardData.profileModel.createDateTime, { day: "2-digit", month: "2-digit", year: "numeric" });
		} else if (dashboardData.profileModel) {
			dashboardData.profileModel.createDateTime = null;
		}

		if (dashboardData.myAccountRentals) {
			dashboardData.myAccountRentals
				.sort((a, b) => b.rentalId - a.rentalId)
				.forEach((rental) => {
					const timeStartIndex = this.$time.text24.indexOf(rental.itinerary.pickupTime);
					const timeEndIndex = this.$time.text24.indexOf(rental.itinerary.dropOffTime);

					rental.itinerary.dropOffDate = this.$date.timeModeToDate(rental.itinerary.dropOffDate, { day: "2-digit", month: "2-digit", year: "numeric" });
					rental.itinerary.dropOffTime = {
						index: timeEndIndex,
						text24: this.$time.text24[timeEndIndex],
						text12: this.$time.text12[timeEndIndex],
					};
					rental.itinerary.pickupDate = this.$date.timeModeToDate(rental.itinerary.pickupDate, { day: "2-digit", month: "2-digit", year: "numeric" });
					rental.itinerary.pickupTime = {
						index: timeStartIndex,
						text24: this.$time.text24[timeStartIndex],
						text12: this.$time.text12[timeStartIndex],
					};
				});
		}
		state.dashboard = dashboardData;
	},
	updateProfile(state, profile) {
		if (profile.createDateTime) {
			profile.createDateTime = this.$date.timeModeToDate(profile.createDateTime, { day: "2-digit", month: "2-digit", year: "numeric" });
		}
		state.dashboard.profileModel = profile;
	},
	updateDriverLicense(state, licenseModel) {
		if (licenseModel.dateOfBirth && licenseModel.dateOfBirth !== "0001-01-01T00:00:00") {
			licenseModel.dateOfBirth = this.$date.timeModeToDate(licenseModel.dateOfBirth, { day: "2-digit", month: "2-digit", year: "numeric" });
		} else {
			licenseModel.dateOfBirth = null;
		}
		if (licenseModel.expiryDate && licenseModel.expiryDate !== "0001-01-01T00:00:00") {
			licenseModel.expiryDate = this.$date.timeModeToDate(licenseModel.expiryDate, { day: "2-digit", month: "2-digit", year: "numeric" });
		} else {
			licenseModel.expiryDate = null;
		}
		if (!state.dashboard.licenceDetailModel || state.dashboard.licenceDetailModel == null) {
			state.dashboard.licenceDetailModel = licenseModel;
		} else {
			state.dashboard.licenceDetailModel.name = licenseModel.name;
			state.dashboard.licenceDetailModel.dateOfBirth = licenseModel.dateOfBirth;
			state.dashboard.licenceDetailModel.number = licenseModel.number;
			state.dashboard.licenceDetailModel.expiryDate = licenseModel.expiryDate;
			state.dashboard.licenceDetailModel.country = licenseModel.country;

			if (licenseModel.id) {
				state.dashboard.licenceDetailModel.id = licenseModel.id;
			}
			if (licenseModel.storage) {
				state.dashboard.licenceDetailModel.storage = licenseModel.storage;
			}
			if (licenseModel.customerProfileId) {
				state.dashboard.licenceDetailModel.customerProfileId = licenseModel.customerProfileId;
			}
		}
	},
	updateAgentDetails(state, agentDetails) {
		state.agentDetails = agentDetails
	},
	updateAgentProfile(state, profile) {
		state.user.firstName = profile.name
	},
};

export const actions = {
	async login({ commit, dispatch }, params) {
		const dataModel = {
			userName: params?.userName,
			password: params.password,
		};
		const response = await this.$axios.$post("auth/login", dataModel);
		const result = response.result;
		if (!result.hasError) {
			dispatch("loginUser", result);
			dispatch("getDashboardData");
			dispatch("scheduleRenewal");
		}
		return result;
	},
	async sendResetPasswordEmail({ commit, dispatch }, params) {
		let isSuccess = false;
		await this.$axios
			.$post("auth/sendResetPasswordEmail", { emailAddress: params })
			.then((res) => {
				if (res.statusCode === 200) {
					isSuccess = true;
				} else {
					isSuccess = false;
				}
			})
			.catch((res) => {
				isSuccess = false;
			});

		return isSuccess;
	},
	setFirstLogin({ commit }, result) {
		commit("firstLogin", result);
	},
	loginUser({ commit }, result) {
		commit("loginUser", result);
	},
	logout({ commit, state }) {
		const isAgentCorporate = [UserType.Agent, UserType.Corporate].includes(state.user.userType);
		if (!!state.tokenInfo && !!state.tokenInfo.token && state.tokenInfo.token !== "") {
			this.$axios.$get("auth/logout", { headers: { Authorization: "Bearer " + state.tokenInfo.token } });
		}
		commit("logOutUser");
		commit("booking/resetReservation", null, { root: true })
		clearTimeout(tokenRenewalTimeout);
		isAgentCorporate ? this.$router.push({ path: "/business/sign-in/" }) : this.$router.push({ path: "/" });
	},
	async renewToken({ commit, dispatch, state }) {
		try {
			const self = this;
			const res = await self.$axios.$get("auth/renewToken", { params: { async: true }, headers: { Authorization: "Bearer " + state.tokenInfo.token } });
			if (!!res && !res.result.hasError) {
				commit("loginUser", res.result);
				dispatch("scheduleRenewal");
			} else {
				dispatch("logout");
			}
		} catch (err) {
			dispatch("logout");
		}
	},
	scheduleRenewal({ commit, dispatch, state }) {
		try {
			const expiresAt = new Date(state.tokenInfo.tokenExpiresAt);
			const now = Date.now();
			const delay = expiresAt - now - 30000; // renew token 30 seconds earlier
			if (delay > 0) {
				clearTimeout(tokenRenewalTimeout);
				tokenRenewalTimeout = setTimeout(function () {
					dispatch("renewToken");
				}, delay);
			} else {
				dispatch("renewToken");
			}
		} catch (err) {
			dispatch("renewToken");
		}
	},
	authenticateLogin({ commit, dispatch, state }) {
		if (this.$cookies.get("tokenInfo").token) {
			clearTimeout(tokenRenewalTimeout);
			dispatch("renewToken");
		}
	},
	async getDashboardData({ commit, dispatch, state }) {
		if ([UserType.Customer].includes(state.user?.userType)) {
			const res = await this.$axios.$get("myAccount/getDashboard", { headers: { Authorization: "Bearer " + state.tokenInfo.token } });
			if (!!res && !res.result.hasError) {
				commit("updateDashboard", res.result);
			}
		}
	},
	async getAgentDetails({ commit, state }) {
		if (state.agentDetails) return state.agentDetails
		const { result } = await this.$axios.$get("agent/details", { headers: { Authorization: "Bearer " + state.tokenInfo.token } })
		if (!result.hasError) {
			commit("updateAgentDetails", result)
			return result
		}
	},
	async updateProfile({ commit, dispatch, state }, profile) {
		const result = {
			hasError: true,
			errorDescription: null,
		};
		try {
			if (profile.createDateTime) {
				profile.createDateTime = this.$date.timeModeToDate(profile.createDateTime);
			}
			const res = await this.$axios.$post("myAccount/saveProfile", profile, { headers: { Authorization: "Bearer " + state.tokenInfo.token } });
			if (res.statusCode === 200) {
				result.hasError = false;
				Object.assign(result, result, res.result);
				commit("updateProfile", res.result);
			} else {
				result.hasError = true;
				result.errorDescription = res.title ? res.title : "Save request could not be completed.";
			}
		} catch (err) {
			result.hasError = true;
			result.errorDescription = "An error has occured, please try again later or let us know if the problem persists.";
		}

		return result;
	},
	async saveAgentProfile({ commit, state }, profile) {
		const result = {
			hasError: false,
			errorDescription: null,
		}
		try {
			const res = await this.$axios.$post("agent/saveProfile", profile, { headers: { Authorization: "Bearer " + state.tokenInfo.token } })
			if (res.statusCode === 200) {
				result.hasError = false
				Object.assign(result, res.result)
				commit("updateAgentProfile", profile)
			} else {
				result.hasError = true;
				result.errorDescription = res.title || "Save request could not be completed."
			}
		} catch (err) {
			result.hasError = true;
			result.errorDescription = "An error has occured, please try again later or let us know if the problem persists."
		}
		return result
	},
	async updateDriverLicense({ commit, dispatch, state }, license) {
		const result = {
			hasError: true,
			errorDescription: null,
		};
		try {
			license.dateOfBirth = this.$date.timeModeToDate(license.dateOfBirth);
			license.expiryDate = this.$date.timeModeToDate(license.expiryDate);
			const res = await this.$axios.$post("myAccount/saveDriverLicence", license, { headers: { Authorization: "Bearer " + state.tokenInfo.token } });
			if (res.statusCode === 200) {
				result.hasError = false;
				Object.assign(result, result, res.result);
				commit("updateDriverLicense", res.result);
			} else {
				result.hasError = true;
				result.errorDescription = res.title ? res.title : "Save request could not be completed.";
			}
		} catch (err) {
			result.hasError = true;
			result.errorDescription = "An error has occured, please try again later or let us know if the problem persists.";
		}
		return result;
	},
	async updatePassword({ commit, dispatch, state }, passwordModel) {
		let result = null;
		try {
			const res = await this.$axios.$post("auth/changePassword", passwordModel, { headers: { Authorization: "Bearer " + state.tokenInfo.token } });
			if (!!res.statusCode && res.statusCode === 200) {
				result = res;
				Object.assign(result, { hasError: false });
			} else {
				result = res;
			}
		} catch (err) {
			result = { hasError: true, errorDescription: "An error has occured, please try again later or let us know if the problem persists." };
		}
		return result;
	},
};
